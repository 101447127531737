package com.uludi.bard.components

import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

object Spacer {
    object H {
        @Composable
        fun XS() {
            HSpacer(2.dp)
        }

        @Composable
        fun S() {
            HSpacer(6.dp)
        }

        @Composable
        fun M() {
            HSpacer(12.dp)
        }

        @Composable
        fun L() {
            HSpacer(32.dp)
        }

        @Composable
        fun XL() {
            HSpacer(64.dp)
        }
    }

    object V {
        @Composable
        fun XS() {
            VSpacer(2.dp)
        }

        @Composable
        fun S() {
            VSpacer(6.dp)
        }

        @Composable
        fun M() {
            VSpacer(12.dp)
        }

        @Composable
        fun L() {
            VSpacer(32.dp)
        }

        @Composable
        fun XL() {
            VSpacer(64.dp)
        }
    }
}

@Composable
private fun VSpacer(height: Dp) {
    Spacer(Modifier.height(height))
}

@Composable
private fun HSpacer(width: Dp) {
    Spacer(Modifier.width(width))
}